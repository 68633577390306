<template>
  <Form
    :breadcrumbHome="breadcrumbHome"
    :breadcrumbItems="breadcrumbItems"
    :event="event"
    :message="message"
    @save="save"
  />
</template>

<script>
import Form from '../../../components/Event/Form';
import { reactive, ref } from 'vue';
import axios from '@/plugins/axios';
import { useRouter } from 'vue-router';
import EventModel from '@/models/event';

export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [
      { label: 'My Task', to: '/events/tasklist?_type=Task' },
      { label: 'My Rdv', to: '/events/rdvlist?_type=Rdv' },
      { label: 'My Event', to: '/events/eventlist?_type=Event' }
    ]
  }),
  components: {
    Form
  },
  setup() {
    const router = useRouter();

    const event = reactive(new EventModel({}));

    const message = ref();

    async function save() {
      try {
        await axios.post('/event', event);

        message.value = [
          { severity: 'success', content: 'Saved successfully' }
        ];
        const type = event._type;
        switch (type) {
          case 'Event':
            router.push({ path: '/events/eventlist', query: { _type: type } });
            break;
          case 'Task':
            router.push({ path: '/events/tasklist', query: { _type: type } });
            break;
          default:
            router.push({ path: '/events/rdvlist', query: { _type: type } });
        }
      } catch (e) {
        message.value = [
          { severity: 'error', content: 'Oops, something went wrong!' }
        ];
        console.log(e);
      }
    }

    return {
      event,
      save,
      message
    };
  }
};
</script>